import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./register.css";
import Header from "../components/Header";
import RegisterForm from "../components/DancerForm/RegisterForm.js";
import FlingInfo from "../components/FlingInfo";
import { appConfig } from "../config.js";
const NODEURL = appConfig.NODEURL;

export default function Register() {
  const location = useLocation();
  let { cartItems } = location.state || {};
  return (
    <Header title="Fling Registration">
      <div
        id="topofpage"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          // width: 260,
          margin: "auto",
        }}
        className="App"
      >
        <FlingInfo />
        <RegisterForm cartItems={cartItems} />

        <div
          style={{
            border: "1px solid black",
            padding: 10,
            margin: 10,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <strong>Scholarships</strong> available (while openings last.) For
            details contact Clint Cummins at&nbsp;
            <a href="mailto:skycamp65@gmail.com">skycamp65@gmail.com</a>
          </div>
          <div>
            Cancellations, Refunds & COVID Policy
            <div>
              <div>
                A "full refund", where indicated below, does not include PayPal
                fees (which are about 2.4%)
              </div>
              <div>
                If you think you or anyone in your household has COVID, if you
                are experiencing COVID symptoms, or if you have been exposed to
                COVID shortly before the dance, we encourage you to stay home.
                We will give you a full refund.
              </div>
              <div>
                You may cancel your registration up to a month before the dance
                (through Oct 15th) and receive a full refund.
              </div>
              <div>
                Cancellation between one month and 7 days (Oct 16th through Nov
                8th) before the dance will be a $10 charge, plus PayPal fees.
              </div>
              <div>
                Cancellation less than 7 days (after Nov 8th) before the dance
                will be a $20 charge, plus PayPal fees.
              </div>
            </div>
          </div>
        </div>
      </div>
    </Header>
  );
}
