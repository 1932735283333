import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
// import { CSVLink } from "react-csv";
import {
  createTheme,
  useTheme,
  styled,
  alpha,
  ThemeProvider,
} from "@mui/material/styles";

import InputBase from "@mui/material/InputBase";
import CloseIcon from "@mui/icons-material/Close";
import "./schedule.css";

import Header from "../components/Header";

const Schedule = () => {
  const theme = useTheme();

  const StyledInfo = styled("div")({
    fontFamily: "adobe-garamond-pro",
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    boxShadow: theme.shadows[5],
    borderRadius: 10,
    maxWidth: 400,
    minWidth: 250,
    height: "auto",
    margin: "auto",
    padding: 6,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    fontSize: "1.2em",
    lineHeight: "1.4em",
    gap: 5,
  });

  return (
    <div style={{}}>
      <div
        style={{
          ...theme.typography.h4,
          fontWeight: 800,
          margin: "auto",
          marginBottom: 10,
          width: "fit-content",
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          borderRadius: 10,
          padding: 10,
          boxShadow: theme.shadows[10],
        }}
      >
        Fleur de Lis Fling 2024 Daily Schedule
      </div>
      <div
        style={{
          display: "flex",
          // border: "4px solid purple",
          justifyContent: "space-evenly",
          alignItems: "start",
          flexWrap: "wrap",
          gap: 10,
          // backgroundColor: "#f0f0f0",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <StyledInfo>
            <div>
              <i>
                <b>Note</b>
              </i>{" "}
              our callers will alternate who calls gents & ladies or larks &
              robins each night. Both terms may be used all weekend.
            </div>
          </StyledInfo>

          <StyledInfo className="schedule-section">
            <div className="location-section">
              <div className="title">Main Dance Hall</div>
              <div>Highland Community Ministries</div>

              <a
                style={{
                  display: "block",
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                }}
                target="_new"
                href="https://www.google.com/maps/place/1228+E+Breckinridge+St,+Louisville,+KY+40204/@38.2399213,-85.731373,17z/data=!3m1!4b1!4m6!3m5!1s0x886973d3348ad903:0x7bc8efaffe4f4972!8m2!3d38.2399213!4d-85.731373!16s%2Fg%2F11t_knzzyg?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D"
              >
                <div>1228 E Breckinridge Street</div>
                <div>Louisville, KY 40204</div>
              </a>
            </div>
          </StyledInfo>

          <StyledInfo className="schedule-section">
            <div id="sundaylocation" className="location-section">
              <div className="title">Sunday Morning Location</div>
              <div>Louisville Dance Alliance</div>
              <a
                style={{
                  display: "block",
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                }}
                target="_new"
                href="https://www.google.com/maps/place/845+Barret+Ave,+Louisville,+KY+40204/@38.2405284,-85.7336964,17z/data=!3m1!4b1!4m6!3m5!1s0x886972d360b26ff3:0xc5a89a2fa60b755b!8m2!3d38.2405284!4d-85.7311215!16s%2Fg%2F11bw3zq4k2?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D"
              >
                <div>845 Barret Ave</div>
                <div> Louisville, KY 40204</div>
              </a>

              <div style={{ fontSize: "smaller" }}>
                (catty corner from the main dance hall)
              </div>
            </div>
          </StyledInfo>
        </div>

        <StyledInfo className="schedule-section">
          <div className="title">Friday</div>
          <div className="column">
            <div className="row">
              <div className="time">7:00 PM</div>
              <div className="event">Doors Open</div>
            </div>
            <div className="row">
              <div className="time">7:15–7:45 PM</div>
              <div className="event">Waltzing with Double Trouble</div>
            </div>
            <div className="row">
              <div className="time">8:00–9:30 PM</div>
              <div className="event">
                Contra with Bob Isaacs and Double Trouble
              </div>
            </div>
            <div className="row">
              <div className="time">10:00–11:30 PM</div>
              <div className="event">
                Contra with River Rainbowface and Electric EEL
              </div>
            </div>
          </div>
          <div>
            Blues/ Fusion After Party Friday 11:45&nbsp;pm to 3&nbsp;am
            <a
              style={{
                display: "block",
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
              }}
              target="_new"
              href="https://www.google.com/maps/place/Ambo+Dance+Theatre/@38.2131174,-85.7434062,17z/data=!3m1!4b1!4m6!3m5!1s0x88690d353ac2f637:0x297d39ee2f8ee4a0!8m2!3d38.2131174!4d-85.7408313!16s%2Fg%2F11nmh_ytcl?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D"
            >
              Ambo Dance Theatre, 808 Clarks Ln, Louisville, KY 40217
            </a>
            <div style={{ fontSize: "smaller" }}>
              $10 entry; curated DJ sets and snacks hosted by the Louisville
              Blues Dance Studio&nbsp;
              <a
                style={{
                  display: "inline",
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                }}
                target="_new"
                href="https://www.facebook.com/events/581614937532862/"
              >
                Fusion After Party to FDLF
              </a>
            </div>
          </div>
        </StyledInfo>

        <StyledInfo className="schedule-section">
          <div className="title">Saturday Dance Workshops</div>
          <div className="column">
            <div className="row">
              <div className="time">10:00–11:15 AM</div>
              <div className="event">
                Square Dance with Bob Isaacs and Tom, Jim, & John
              </div>
            </div>
            <div className="row">
              <div className="time">11:30 AM–1:00 PM</div>
              <div className="event">
                Role-Swapping Mid-Dance with River Rainbowface and Double
                Trouble
              </div>
            </div>
            <div className="row">
              <div className="time">1:00–2:30 PM</div>
              <div className="event">Lunch</div>
            </div>
            <div className="row">
              <div className="time">2:30–3:45 PM</div>
              <div className="event">
                Waltz Workshop with Ed Howe and Tami Dahl
              </div>
            </div>
            <div className="row">
              <div className="time">4:00–5:30 PM</div>
              <div className="event">
                Wacky Contras with Bob Isaacs and Electric EEL
              </div>
            </div>
          </div>
        </StyledInfo>

        <StyledInfo className="schedule-section">
          <div className="title">Saturday Music/Singing Workshops</div>
          <div className="column">
            <div className="row">
              <div className="time">10:00–11:15 AM</div>
              <div className="event">Community singing with Callie Allison</div>
            </div>
            <div className="row">
              <div className="time">11:30 AM–1:00 PM</div>
              <div className="event">
                Playing Music for Dancers with Emily Troll
              </div>
            </div>
            <div className="row">
              <div className="time">1:00–2:30 PM</div>
              <div className="event">Lunch</div>
            </div>
            <div className="row">
              <div className="time">2:30–3:45 PM</div>
              <div className="event">Singing Rounds with River Rainbowface</div>
            </div>

            <div className="row">
              <div className="time">4:00–5:30 PM</div>
              <div className="event">
                Musicians' Jam Led by Local Band Leaders
              </div>
            </div>
          </div>
        </StyledInfo>

        <StyledInfo className="schedule-section">
          <div className="title">Saturday Night</div>
          <div className="column">
            <div className="row">
              <div className="time">7:00 PM</div>
              <div className="event">Doors Open</div>
            </div>
            <div className="row">
              <div className="time">7:15–7:45 PM</div>
              <div className="event">Waltzing with Electric EEL</div>
            </div>
            <div className="row">
              <div className="time">8:00–9:15 PM</div>
              <div className="event">
                Contra with River Rainbowface and Electric EEL
              </div>
            </div>
            <div className="row">
              <div className="time">9:45–11:00 PM</div>
              <div className="event">
                Contra with Bob Isaacs and Double Trouble
              </div>
            </div>
          </div>
        </StyledInfo>

        <StyledInfo className="schedule-section">
          <div className="title">Sunday</div>
          <div className="column">
            <div className="row">
              <div className="time">10:30–11:30 AM</div>
              <div className="event">
                Waltzing with Electric EEL @ Louisville Dance Alliance
              </div>
            </div>
            <div style={{ marginLeft: 20, padding: 0 }}>
              <a
                style={{
                  display: "block",
                  fontSize: "smaller",
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                }}
                target="_new"
                href="https://www.google.com/maps/place/845+Barret+Ave,+Louisville,+KY+40204/@38.2405284,-85.7336964,17z/data=!3m1!4b1!4m6!3m5!1s0x886972d360b26ff3:0xc5a89a2fa60b755b!8m2!3d38.2405284!4d-85.7311215!16s%2Fg%2F11bw3zq4k2?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D"
              >
                <div>845 Barret Ave, Louisville, KY 40204</div>
              </a>
            </div>
            <div className="row">
              <div className="time">11:30 AM–1:30 PM</div>
              <div className="event">Brunch</div>
            </div>
            <div className="row">
              <div className="time">2:00–2:55 PM</div>
              <div className="event">
                Contra with Bob Isaacs and Double Trouble
              </div>
            </div>
            <div className="row">
              <div className="time">3:05–4:00 PM</div>
              <div className="event">
                Contra with River Rainbowface and Electric EEL
              </div>
            </div>
          </div>
          <div>
            <div>Doug and Angela’s after-party 5:00PM- 11:00PM</div>
            <div>
              <div style={{ fontSize: "smaller" }}>
                <a
                  style={{
                    display: "block",
                    color: theme.palette.primary.contrastText,
                    backgroundColor: theme.palette.primary.main,
                  }}
                  target="_new"
                  href="https://www.google.com/maps/place/9797+N+Skyline+Dr,+Floyds+Knobs,+IN+47119/@38.3923958,-85.8271127,17z/data=!3m1!4b1!4m6!3m5!1s0x88696f6a8a46485f:0x9a028cfdfb76125c!8m2!3d38.3923958!4d-85.8271127!16s%2Fg%2F11c1779_68?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D"
                >
                  9797 N Skyline Drive, Floyds Knobs, IN 47119
                </a>
                Soup, Chili, and Toasted Cheese will be provided by the hosts.
                Please bring food to share.
              </div>
            </div>
          </div>
        </StyledInfo>
      </div>
    </div>
  );
};

export default Schedule;
