import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import { appConfig } from "../../config";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Zoom from "@mui/material/Zoom";
import { Button, InputBase, IconButton } from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";
import ConfirmButtons from "../widgets/ConfirmButtons.js";
import "./playlist.css";

const PlaylistForm = ({ block }) => {
  const theme = useTheme();

  const [blockContent, setBlockContent] = useState([]);

  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState({ url: "", label: "", position: 1 });
  // const playlistId = match?.params?.id || 0;
  const [playlistId, setPlaylistId] = useState("");

  const [playlists, setPlaylists] = useState([]);

  const [newname, setNewname] = useState("");

  const fetchPlaylists = async () => {
    try {
      const response = await axios.get(`${appConfig.NODEURL}api/playlists`);

      setPlaylists(response.data);
    } catch (error) {
      console.error("Error fetching playlists", error);
    }
  };

  const fetchPlaylist = async () => {
    try {
      const response = await axios.get(
        `${appConfig.NODEURL}api/playlists/${playlistId}`
      );
      setNewname(response.data.playlist.name);
      setItems(
        response.data.items.sort((a, b) => {
          if (a.position < b.position) {
            return -1;
          }
          if (a.position > b.position) {
            return 1;
          }
          return 0;
        })
      );
    } catch (error) {
      console.error("Error fetching playlist", error);
    }
  };

  const fetchBlock = async () => {
    try {
      //   console.log(block);
      //setBlocks([...blocks, res.data]);
      const res = await axios.get(
        `${appConfig.NODEURL}api/blocks/${block.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Add content-type if sending JSON
          },
        }
      );
      console.log(res.data);
      setBlockContent(res.data.content);
      // fetchBlocks();
      // setBlocks(
      //   blocks.map((block) => (block.id === res.data.id ? res.data : block))
      // );
      // setBlockContent(res.data);
      // resetForm();
    } catch (err) {
      console.error("Error saving block:", err);
    }
  };

  useEffect(() => {
    setBlockContent(block);
    fetchPlaylists();
    return () => {};
  }, []);

  useEffect(() => {
    if (playlistId) {
      fetchPlaylist();
    }
    return () => {};
  }, [playlistId]);

  useEffect(() => {
    block && fetchBlock();
    return () => {};
  }, []);

  const handleAddItem = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${appConfig.NODEURL}api/playlists/${playlistId}/items`,
        newItem,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Add content-type if sending JSON
          },
        }
      );
      setItems(
        [...items, response.data].sort((a, b) => {
          if (a.position < b.position) {
            return -1;
          }
          if (a.position > b.position) {
            return 1;
          }
          return 0;
        })
      );
      setNewItem({ url: "", label: "" });
    } catch (error) {
      console.error("Error adding item", error);
    }
  };

  // const handleUpdate = async (playlist) => {
  //   // const data = { name };
  //   await axios.put(
  //     `${appConfig.NODEURL}api/playlists/${playlistId}`,
  //     playlist,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
  //         "Content-Type": "application/json", // Add content-type if sending JSON
  //       },
  //     }
  //   );
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { name: newname };
    await axios.post(`${appConfig.NODEURL}api/playlists`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
        "Content-Type": "application/json", // Add content-type if sending JSON
      },
    });
    // alert("Playlist saved successfully!");
    fetchPlaylists();
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${appConfig.NODEURL}api/playlists/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
          "Content-Type": "application/json", // Add content-type if sending JSON
        },
      });
      //alert("Playlist deleted successfully!");
      // setPlaylists(playlists.filter((playlist) => playlist.id !== id));
      fetchPlaylists();
    } catch (error) {
      console.error("Error deleting playlist", error);
    }
  };

  const handleItemChange = async (event, id) => {
    const value = event.target.value;
    const field = event.target.name;
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const deletePlaylistItem = async (id) => {
    try {
      await axios.delete(`${appConfig.NODEURL}api/playlists/items/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
          "Content-Type": "application/json", // Add content-type if sending JSON
        },
      });
      console.log("Page deleted successfully!");
      fetchPlaylist();
    } catch (error) {
      console.error("Error deleting page", error);
    }
  };

  const updatePlaylistItem = async (item) => {
    try {
      const response = await axios.put(
        `${appConfig.NODEURL}api/playlists/${item.id}/items`,
        {
          url: item.url,
          label: item.label,
          position: item.position,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Content-type header for JSON
          },
        }
      );
      fetchPlaylist();
      // setPlaylists(response.data);
    } catch (error) {
      console.error("Error fetching playlists", error);
    }
  };

  // setBlock((prev) => ({ ...prev, [event.target.name]: event.target.value }))
  const [show, setShow] = useState("false");

  const PlayLists = ({ fetchPlaylists, _name, playlistId }) => {
    const [name, setName] = useState(_name);

    const handleUpdate = async (playlist) => {
      // const data = { name };
      console.log(playlist);
      console.log(name);
      try {
        await axios.put(
          `${appConfig.NODEURL}api/playlists/${playlistId}`,
          { ...playlist, name: name },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
              "Content-Type": "application/json", // Add content-type if sending JSON
            },
          }
        );
        // fetchPlaylists();
        // fetchPlaylist();
      } catch (err) {}
    };

    const handleRemoveBlockContent = async () => {
      try {
        const resp = await fetch(`${appConfig.NODEURL}api/blocks/${block.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            block_type: block.block_type,
            content: {},
            position: block.position,
            label: block.label,
          }),
        });

        // Check if the response is OK
        if (!resp.ok) {
          console.log(`Error: ${resp.statusText}`);
          return;
        }

        // Parse the JSON data from the response
        const data = await resp.json();
        console.log(data); // Log the updated block data
        setBlockContent(data.content); // Set the updated content to state
      } catch (err) {
        console.error("Fetch error:", err);
      }
    };

    const handleAddBlockContent = async (playlist, items) => {
      playlist.items = items;
      try {
        const resp = await fetch(`${appConfig.NODEURL}api/blocks/${block.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            block_type: block.block_type,
            content: playlist,
            position: block.position,
            label: block.label,
          }),
        });

        // Check if the response is OK
        if (!resp.ok) {
          console.log(`Error: ${resp.statusText}`);
          return;
        }

        // Parse the JSON data from the response
        const data = await resp.json();
        console.log(data); // Log the updated block data
        setBlockContent(data.content); // Set the updated content to state
      } catch (err) {
        console.error("Fetch error:", err);
      }
    };

    const BlockContent = () => {
      if (!blockContent.name) {
        return null;
      }
      return (
        <div>
          <label>List added</label>
          <label>{blockContent.name}</label>
          <button onClick={() => handleRemoveBlockContent()}>remove</button>

          <pre style={{ display: "none" }}>
            {JSON.stringify(blockContent, null, 3)}
          </pre>
        </div>
      );
    };
    return (
      <div>
        <pre style={{ display: "none" }}>
          {JSON.stringify(blockContent, null, 3)}
        </pre>
        <BlockContent />
        <div
          style={{
            ...theme.typography.h6,
            display: playlistId ? "none" : "block",
          }}
        >
          Playlists
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            alignItems: "center",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              border: "1px solid black",
              padding: 5,
              height: "2em",
              borderRadius: 5,
              display: playlistId ? "flex" : "none",
              alignItems: "center",
            }}
          >
            <IconButton onClick={() => setPlaylistId("")}>
              <BackspaceIcon />
            </IconButton>

            <a
              // style={{
              //   cursor: "pointer",
              //   border: "1px solid black",
              //   padding: 5,
              //   borderRadius: 5,
              // display: playlistId ? "block" : "none",
              // }}
              onClick={() => setPlaylistId("")}
            >
              {name}
            </a>
          </div>
          {playlists.map((playlist) => (
            <div
              key={playlist.id}
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
                backgroundColor:
                  playlist.id === playlistId
                    ? theme.palette.grey[300]
                    : "#ffffff",
                width: "fit-content",
                margin: 5,
                padding: 5,
                borderRadius: 5,
              }}
            >
              {playlistId === playlist.id && (
                <div>
                  <ConfirmButtons
                    icon={<DeleteForeverIcon />}
                    action={handleDelete}
                    args={playlist.id}
                  />
                </div>
              )}

              {playlistId === playlist.id ? (
                <InputBase
                  style={{ ...theme.typography.inputbase }}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              ) : (
                <a
                  style={{
                    cursor: "pointer",
                    border: "1px solid black",
                    padding: 5,
                    borderRadius: 5,
                    display: playlistId ? "none" : "block",
                  }}
                  onClick={() => setPlaylistId(playlist.id)}
                >
                  {playlist.name}
                </a>
              )}

              {playlistId === playlist.id && (
                <div>
                  <Button
                    sx={{ ...theme.typography.button }}
                    onClick={() => handleUpdate(playlist)}
                  >
                    update
                  </Button>
                  <Button
                    onClick={() => handleAddBlockContent(playlist, items)}
                    style={{ ...theme.typography.button }}
                  >
                    Add to Page
                  </Button>
                  {/* <button onClick={() => setPlaylistId("")}>cancel</button> */}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  // main view
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: playlistId ? "none" : "flex",
            alignItems: "center",
          }}
        >
          <InputBase
            sx={{
              ...theme.typography.inputbase,
              backgroundColor: playlistId ? theme.palette.grey[300] : "#ffffff",
            }}
            placeholder="playlist name"
            value={newname}
            onChange={(e) => setNewname(e.target.value)}
            required
          />
          <Button sx={{ ...theme.typography.button2 }} type="submit">
            Add New Playlist
          </Button>
        </div>
      </form>

      <PlayLists
        fetchPlaylists={fetchPlaylists}
        fetchPlaylist={fetchPlaylist}
        _name={newname}
        playlistId={playlistId}
      />

      {playlistId && (
        <Zoom in={true}>
          <div style={{ margin: 20 }}>
            <form onSubmit={handleAddItem}>
              <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                <InputBase
                  style={{ ...theme.typography.inputbase }}
                  type="text"
                  value={newItem.label}
                  placeholder="Label"
                  onChange={(e) =>
                    setNewItem({ ...newItem, label: e.target.value })
                  }
                  required
                />
                <InputBase
                  style={{ ...theme.typography.inputbase }}
                  type="text"
                  value={newItem.url}
                  placeholder="Video URL"
                  onChange={(e) =>
                    setNewItem({ ...newItem, url: e.target.value })
                  }
                  required
                />

                <label>order</label>
                <InputBase
                  style={{ ...theme.typography.inputbase, width: 50 }}
                  type="number"
                  value={newItem.position}
                  placeholder=""
                  onChange={(e) =>
                    setNewItem({ ...newItem, position: e.target.value })
                  }
                  required
                />
                <Button sx={{ ...theme.typography.button }} type="submit">
                  Add Item
                </Button>
              </div>
            </form>

            <div>
              {items.map((item) => (
                <div
                  key={item.id}
                  style={{ display: "flex", gap: 10, alignItems: "center" }}
                >
                  <InputBase
                    style={{ ...theme.typography.inputbase }}
                    name="label"
                    type="text"
                    value={item.label}
                    onChange={(e) => handleItemChange(e, item.id)}
                  />
                  <InputBase
                    style={{ ...theme.typography.inputbase }}
                    name="url"
                    type="text"
                    value={item.url}
                    onChange={(e) => handleItemChange(e, item.id)}
                  />
                  <label>order</label>
                  <InputBase
                    style={{ ...theme.typography.inputbase, width: 50 }}
                    name="position"
                    type="number"
                    value={item.position}
                    onChange={(e) => handleItemChange(e, item.id)}
                  />
                  <Button
                    sx={{ ...theme.typography.button2 }}
                    name={item.id}
                    onClick={() => updatePlaylistItem(item)}
                  >
                    update
                  </Button>
                  <ConfirmButtons
                    icon={<DeleteForeverIcon />}
                    action={deletePlaylistItem}
                    args={item.id}
                  ></ConfirmButtons>
                </div>
              ))}
            </div>
          </div>
        </Zoom>
      )}
    </div>
  );
};

export default PlaylistForm;
