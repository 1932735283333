import React, { useState, useEffect } from "react";
import { TextField, Button, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "./dancers.css";
import DancerFormField from "./DancerFormField.js";
import ConfirmButtons from "../widgets/ConfirmButtons";
import { appConfig, _fields } from "../../config.js";

const NODEURL = appConfig.NODEURL;

export default function DancerForm({
  dancer,
  resetField,
  setSelectedRow,
  deleteDancer,
  getDancers,
  exclude,
}) {
  const handleChange = (event) => {
    const newField = { ...field };
    if (event.target.name === "student" || event.target.name === "housing") {
      newField[event.target.name] = event.target.checked;
    } else {
      newField[event.target.name] = event.target.value;
    }
    setField(newField);
  };

  const [showNote, setShowNote] = useState("");
  const [showEditButton, setShowEditButton] = useState(false);
  const [undos, setUndos] = useState([]);
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const usPhoneNumberRegex =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

  const [valMsg, setValMsg] = useState([]);
  const [field, setField] = useState({
    lastname: "",
    firstname: "",
    email: "",
    phone: "",
    event: "",
    student: false,
    housing: false,
    unit_amount: 120,
    dietary: "unknown",
    waltzattend: "select...",
    note: "",
  });
  const theme = useTheme();

  const colHeadStyle = {
    ...theme.typography.button2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    padding: 2,
    fontFamily: "Roboto",
  };

  const submitUpdate = async () => {
    fetch(`${NODEURL}api/update-dancer`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dancer: field }), // Send the object in the request body
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        return response.json(); // Parse the JSON from the response
      })
      .then((data) => {
        // console.log("Received back:", data); // The object sent back by the server
        // You can now use this object to implement an undo operation
        const newUndo = [...undos];
        newUndo.push(data);
        setUndos(newUndo);
        getDancers();
        // setSelectedRow("");
        resetField();
        setSelectedRow("");
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        setSelectedRow("");
      });
  };

  const [dancers, setDancers] = useState([]);
  const [forward, setForward] = useState(true);
  const [err, setErr] = useState("");

  // const headers = [
  //   { label: "First Name", key: "firstname" },
  //   { label: "Last Name", key: "lastname" },
  //   { label: "Email", key: "email" },
  //   { label: "Phone", key: "phone" },
  //   { label: "Event", key: "event" },
  //   { label: "Student", key: "student" },
  //   { label: "Diet", key: "dietary" },
  //   { label: "Waltz", key: "waltzattend" },
  //   { label: "Note", key: "note" },
  //   { label: "Paid", key: "unit_amount" },
  //   { label: "Date/time", key: "createdOn" },
  //   { label: "OrderID", key: "orderId" },
  // ];

  const [headers, setHeaders] = useState([]);

  const [aCode, setACode] = useState("");

  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState("");

  const [showSearchResults, setShowSearchResults] = useState(
    searchResults.length > 0 || search !== ""
  );

  useEffect(() => {
    console.log(exclude);
    console.log(_fields);
    const filteredHeaders = Object.keys(_fields)
      .filter((key) => !exclude.includes(key)) // Exclude keys listed in the exclude array
      .map((key) => ({
        label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize label
        key: key, // Set the key directly
      }));

    setHeaders(filteredHeaders);
    console.log(filteredHeaders);
    setField(dancer);
  }, []);

  const [accessCode, setAccessCode] = useState("LCD3233");

  const [prompt, setPrompt] = useState("Enter >>");

  const [remember, setRemember] = useState(true);

  return (
    <div style={{ margin: 20 }}>
      <div className="noprint">
        <Box
          key={"box1"}
          sx={{
            // backgroundColor: "#000000",
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            borderRadius: 2,
            border: `2px none ${theme.palette.success.main}`,
            padding: 3,
            margin: 0,
            position: "relative",
            backgroundColor: theme.palette.grey[400],
            color: theme.palette.info.contrastText,
            // color: selectedRow ? "#ffffff" : "#000000",
          }}
        >
          <DancerFormField
            key={0}
            field={field}
            index={0}
            handleChange={(event, index) => handleChange(event, index)}
            // validateBoxes={validateBoxes}
          />
          <TextField
            size="small"
            type="number"
            style={{ width: 100 }}
            title="Price (required)"
            label="Price is required"
            name="price"
            id={"price"}
            key={"price"}
            value={field.price}
            onChange={(event) => handleChange(event)}
          />
          <textarea
            value={field.note}
            name="note"
            onChange={(event) => handleChange(event)}
            placeholder="note"
            rows="1"
          ></textarea>
          <pre style={{ display: "none" }}>
            {JSON.stringify(field, null, 3)}
          </pre>
          <div style={{ display: "flex", gap: 20 }}>
            <div>
              {new Date(field.createdOn).toDateString()}{" "}
              {new Date(field.createdOn).toLocaleTimeString()}
            </div>
            <div>{field.orderId}</div>
          </div>
          <div style={{ display: "flex", gap: 20 }}>
            <div
              style={{
                position: "absolute",
                top: -14,
                left: 0,
                display: "flex",
                // flexDirection: "column",
                gap: 10,
                backgroundColor: theme.palette.info.main,
                padding: 3,
                borderRadius: 5,
              }}
            >
              <Button
                onClick={submitUpdate}
                variant="contained"
                key="update"
                style={{
                  ...theme.typography.button2,
                  backgroundColor: theme.palette.success.main,
                }}
              >
                Update
              </Button>
              <Button
                onClick={resetField}
                variant="contained"
                key="reset"
                style={{
                  ...theme.typography.button2,
                  // display: showEditButton ? "block" : "none",
                  backgroundColor: theme.palette.secondary.main,
                  color: theme.palette.secondary.contrastText,
                  // height: "2em",
                  // lineHeight: "1em",
                }}
              >
                Cancel
              </Button>
              <ConfirmButtons
                className="noprint"
                // icon={<CloseIcon />}

                label="Delete"
                action={deleteDancer}
                args={dancer}
              ></ConfirmButtons>
            </div>
          </div>
        </Box>
      </div>

      <h2
        style={{
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        }}
      >
        {err.message}
      </h2>
      <pre style={{ display: "none" }}>{JSON.stringify(dancers, null, 3)}</pre>
    </div>
  );
}
