import { Node } from "@tiptap/core";
import ReactDOM from "react-dom";
import React from "react";
import { useTheme, Box, Typography } from "@mui/material";

const HeroBlock = Node.create({
  name: "heroBlock",
  group: "block", // Block-level node
  content: "inline*", // Allow inline content like images and text

  addAttributes() {
    return {
      hero: {
        default: {
          title: "",
          subtitle: "",
          image_url: "",
          imgmarkup: "",
          description: "",
        },
        parseHTML: (element) =>
          JSON.parse(element.getAttribute("data-hero")) || {},
        renderHTML: (attributes) => {
          return { "data-hero": JSON.stringify(attributes.hero) };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "div[data-hero-block]", // Identify the hero block when switching views
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", { "data-hero-block": true, ...HTMLAttributes }, 0];
  },

  addNodeView() {
    return ({ node }) => {
      const container = document.createElement("div");
      container.setAttribute("data-hero-block", "true");

      const ReactHeroBlock = () => {
        const theme = useTheme(); // Access the MUI theme
        const { title, subtitle, description, imgmarkup } = node.attrs.hero;

        return (
          <Box
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: theme.shape.borderRadius,
              // padding: theme.spacing(2),
              // margin: theme.spacing(1),
              backgroundColor: theme.palette.background.default,
              maxWidth: 600,
            }}
          >
            {/* Title */}
            {title && (
              <Typography variant="h4" gutterBottom>
                {title}
              </Typography>
            )}

            {/* Image markup */}

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <Box
                  dangerouslySetInnerHTML={{ __html: imgmarkup }}
                  style={{ float: "left", margin: theme.spacing(1) }}
                />
                {/* Description */}
                {description && (
                  <Typography
                    variant="body1"
                    sx={{ marginTop: theme.spacing(1) }}
                  >
                    {description}
                  </Typography>
                )}
              </div>
              {/* Subtitle */}
              {subtitle && (
                <Typography variant="body2" color="text.secondary">
                  {subtitle}
                </Typography>
              )}
            </div>
          </Box>
        );
      };

      // Render the ReactHeroBlock component into the container
      ReactDOM.render(<ReactHeroBlock />, container);

      return {
        dom: container,
        contentDOM: null, // No editable children inside this block
      };
    };
  },

  addCommands() {
    return {
      insertHeroBlock:
        (hero) =>
        ({ commands }) => {
          return commands.insertContent({
            type: "heroBlock",
            attrs: { hero }, // Pass the entire hero object as an attribute
          });
        },
    };
  },
});

export default HeroBlock;
