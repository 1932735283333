import React, { useMemo } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit"; // Import extensions as needed
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import Underline from "@tiptap/extension-underline";
// import Gapcursor from "@tiptap/extension-gapcursor";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import Typography from "@tiptap/extension-typography";
import FontSize from "tiptap-extension-font-size";
import FontFamily from "@tiptap/extension-font-family";
import Link from "@tiptap/extension-link";
import bgColor from "../Rte/bgColor.ts";
import CustomDiv from "../Rte/CustomDiv.js";
import LabelStyle from "../Rte/LabelStyle.js";
import HeroBlock from "../Rte/HeroBlock.js";
import PlaylistBlock from "../Rte/PlaylistBlock.js";
// import CustomTableCell from "./CustomTableCell.js";
import ConfirmButtons from "../widgets/ConfirmButtons.js";
import FlexBlock from "../Rte/FlexBlock.js";
import { SmilieReplacer } from "../Rte/SmilieReplacer.ts";

const ReadOnlyEditor = ({ content }) => {
  const CustomTableCell = TableCell.extend({
    addAttributes() {
      return {
        // extend the existing attributes …
        ...this.parent?.(),

        // and add a new one …
        backgroundColor: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-background-color"),
          renderHTML: (attributes) => {
            return {
              "data-background-color": attributes.backgroundColor,
              style: `background-color: ${attributes.backgroundColor}`,
            };
          },
        },
        color: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-color"),
          renderHTML: (attributes) => {
            return {
              "data-color": attributes.color,
              style: `color: ${attributes.color}`,
            };
          },
        },
      };
    },
  });
  const editor = useEditor({
    extensions: [
      StarterKit,
      CustomDiv,
      LabelStyle,
      HeroBlock,
      PlaylistBlock,
      FlexBlock,
      bgColor,

      Underline,
      TextStyle,
      Color,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      Link.configure({
        openOnClick: true,
        autolink: true,
        defaultProtocol: "https",
      }),
      CustomTableCell,
      TableHeader,
      Typography,
      FontSize,
      FontFamily,
      SmilieReplacer,
    ],
    content,
    editable: false, // Disables editing
  });

  // Unmount the editor when component is removed
  useMemo(() => {
    return () => {
      if (editor) editor.destroy();
    };
  }, [editor]);

  return <EditorContent editor={editor} />;
};

export default ReadOnlyEditor;
