import React, { useState } from "react";
import axios from "axios";
import { appConfig } from "../../config";
import { TextField, Button } from "@mui/material";

import { useTheme } from "@mui/material/styles";

const JWTRegister = () => {
  const theme = useTheme();
  const [email, setEmail] = useState(localStorage.getItem("lastEmail"));
  const [password, setPassword] = useState(localStorage.getItem("lastPass"));
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${appConfig.NODEURL}api/auth/register`,
        {
          email,
          password,
        }
      );
      console.log(response.data.message);
      setMessage(response.data.message);
    } catch (error) {
      console.error(error);
      console.log("Registration failed " + error?.response?.data?.message);
      setMessage(error?.response?.data?.message);
    }
  };

  return (
    <div style={{ marginTop: 20, display: "block", flexWrap: "wrap" }}>
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex", gap: 5 }}>
          <Button sx={{ ...theme.typography.button }} type="submit">
            Register
          </Button>
          <div
            onClick={() => setMessage("")}
            style={{ color: "#000000", backgroundColor: "#ffffff" }}
          >
            {message}
          </div>
        </div>
        <TextField
          size="small"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          style={{ width: 100 }}
          size="small"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </form>
    </div>
  );
};

export default JWTRegister;
