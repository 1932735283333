import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Modal,
  Box,
  Select,
  MenuItem,
  useTheme,
  IconButton,
  Checkbox,
  //   TextField,
} from "@mui/material";
import { useAsyncValue, useLocation, useNavigate } from "react-router-dom";
import BackspaceIcon from "@mui/icons-material/Backspace";
import Rte from "../Rte";
import axios from "axios";

import { appConfig } from "../../config";
import ConfirmButtons from "../widgets/ConfirmButtons.js";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const SendEmail = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();
  const [show, setShow] = useState(true);

  const [blockId, setBlockId] = useState(0);
  const [block, setBlock] = useState({});
  const [blocks, setBlocks] = useState([]);

  const [dancerList, setDancerList] = useState(state?.list || []);
  const [selectedDancers, setSelectedDancers] = useState([]);
  const [editorContent, setEditorContent] = useState("");
  const [title, setTitle] = useState("");

  const [newFilename, setNewFilename] = useState("");

  const [editorJson, setEditorJson] = useState("");

  const [all, setAll] = useState(false);

  useEffect(() => {
    if (all) {
      setSelectedDancers(dancerList);
    } else {
      setSelectedDancers([]);
    }
  }, [all]);

  // Callback to handle editor content changes
  const handleContentChange = useCallback((newHtml, newJson, title) => {
    setEditorContent(newHtml); // Update the HTML content
    setEditorJson(newJson); // Update the JSON content
    setTitle(title);
  }, []);

  const sendEmailToList = async () => {
    try {
      await axios.post(
        `${appConfig.NODEURL}api/send-email`,
        {
          recipients: selectedDancers,
          html: editorContent,
          message: editorJson,
          title: title,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Add content-type if sending JSON
          },
        }
      );
      //   refresh();
      //   setMenuId(0);
    } catch (error) {
      console.error("Error updating navigation item:", error);
    }
  };
  // Handle checkbox change
  const handleCheckboxChange = (dancer) => {
    setSelectedDancers((prevSelected) =>
      prevSelected.some((d) => d.orderId === dancer.orderId)
        ? prevSelected.filter((d) => d.orderId !== dancer.orderId)
        : [...prevSelected, dancer]
    );
  };

  const deleteBlock = async () => {
    try {
      // Create new block
      const res = await axios.delete(
        `${appConfig.NODEURL}api/blocks/${blockId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Add content-type if sending JSON
          },
        }
      );
    } catch (err) {}
    fetchBlocks();
    setBlockId(0);
  };

  const addNewFile = async () => {
    const blockData = {
      page_id: 999,
      label: newFilename,
      block_type: "RichText",
      content: "{}",
      position: 1,
      title: newFilename,
    };

    try {
      // Create new block
      const res = await axios.post(
        `${appConfig.NODEURL}api/blocks`,
        blockData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Add content-type if sending JSON
          },
        }
      );
      //setBlocks([...blocks, res.data]);
      setBlockId(res.data.id);
      fetchBlocks();
      setNewFilename("");
    } catch (err) {}
  };

  const SelectButton = () => {
    if (!all) {
      return (
        <Button
          sx={{ ...theme.typography.button }}
          onClick={() => setAll(true)}
        >
          Select All
        </Button>
      );
    }

    return (
      <Button sx={{ ...theme.typography.button }} onClick={() => setAll(false)}>
        Select None
      </Button>
    );
  };

  const DancerList = () => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <SelectButton />
        {dancerList.map((dancer) => (
          <Box
            key={dancer.orderId}
            sx={{
              backgroundColor: theme.palette.grey[200],
              margin: 1,
              padding: 1,
              borderRadius: 5,
              justifyContent: "flex-start",
              gap: 2,
              display: "flex",

              alignItems: "center",
            }}
          >
            <Checkbox
              checked={selectedDancers.some(
                (d) => d.orderId === dancer.orderId
              )}
              onChange={() => handleCheckboxChange(dancer)}
            />
            <div>
              {dancer.firstname}&nbsp;
              {dancer.lastname}
            </div>
          </Box>
        ))}
      </div>
    );
  };

  const fetchBlocks = async () => {
    try {
      const res = await axios.get(`${appConfig.NODEURL}api/blocks/rte`);
      setBlocks(res.data);
      setBlock("");
    } catch (err) {
      console.error("Error fetching blocks:", err);
    }
  };
  useEffect(() => {
    fetchBlocks();
  }, []);

  useEffect(() => {
    const selectedBlock = blocks?.find((b) => b.id === blockId);
    if (selectedBlock) {
      setBlock(selectedBlock);
      //setTitle(selectedBlock.title);
    }
    console.log(selectedBlock); // This ensures you log the correct object
    if (selectedBlock) {
      setEditorJson(selectedBlock.content);
      setEditorContent(selectedBlock.markup);
      setTitle(selectedBlock.title);
    }
  }, [blockId, blocks]); // Add blocks as a dependency in case it changes

  useEffect(() => {
    setDancerList(state?.list || []);
    // setSelectedDancers(state?.list || []);
  }, []);

  useEffect(() => {
    console.log(block);
  }, [block]);
  return (
    <>
      <Button onClick={() => setShow(true)} sx={{ ...theme.typography.button }}>
        Send Email
      </Button>

      {/* Modal Component */}
      <Modal
        style={{ display: show ? "block" : "none" }}
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="send-email-modal"
        aria-describedby="modal-with-rich-text-editor"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: "100vw", // Optional: adjust size as needed
            height: "100vh",
            overflow: "auto",
          }}
        >
          <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
            <IconButton onClick={() => navigate(-1)}>
              <BackspaceIcon />
            </IconButton>

            <Select
              style={{ height: 30 }}
              value={blockId}
              onChange={(e) => setBlockId(e.target.value)}
            >
              <MenuItem value="0">files...</MenuItem>
              {blocks &&
                blocks.map((blk, index) => {
                  return (
                    <MenuItem key={index} value={blk.id}>
                      {blk.label}
                    </MenuItem>
                  );
                })}
            </Select>
            {Number(blockId) !== 0 && (
              <ConfirmButtons
                icon={<DeleteForeverIcon />}
                action={deleteBlock}
              />
            )}

            <Button
              onClick={addNewFile}
              style={{ ...theme.typography.button2 }}
            >
              add new
            </Button>
            <input
              type="text"
              value={newFilename}
              onChange={(e) => setNewFilename(e.target.value)}
            />
          </div>
          <pre style={{ display: "none" }}>
            {JSON.stringify(block, null, 3)}
          </pre>

          {Number(blockId) !== 0 && (
            <Rte
              block={block}
              key={block.id}
              onContentChange={handleContentChange}
            />
          )}
          <Box p={2}>
            <DancerList />
            <Button
              onClick={sendEmailToList}
              sx={{ ...theme.typography.button }}
            >
              Send Email
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SendEmail;
