import React, { useState, useEffect } from "react";
import {
  Box,
  Select,
  MenuItem,
  Button,
  IconButton,
  // TextField,
  // Grid,
  InputBase,
} from "@mui/material";
import axios from "axios";
import { appConfig } from "../../config";
import Zoom from "@mui/material/Zoom";
import { useTheme } from "@mui/material/styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import BackspaceIcon from "@mui/icons-material/Backspace";

import ConfirmButtons from "../widgets/ConfirmButtons.js";

import Rte from "../../components/Rte";
import HeroForm from "./HeroForm.js";
import PlaylistForm from "../Playlist/PlaylistForm.js";
// import PlaylistList from "../Playlist/PlaylistList.js";
import "../Rte/rte.css";

const BlocksCRUD = ({ pageId }) => {
  const theme = useTheme();
  const [blocks, setBlocks] = useState([]);
  const [block, setBlock] = useState(false);
  const [blockType, setBlockType] = useState("");
  const [content, setContent] = useState("");
  const [position, setPosition] = useState("1");
  const [label, setLabel] = useState("");
  const [editingBlock, setEditingBlock] = useState(null); // For updating blocks

  // Fetch blocks for the page
  const fetchBlocks = async () => {
    try {
      const res = await axios.get(
        `${appConfig.NODEURL}api/pages/${pageId}/blocks`
      );
      setBlocks(res.data);
      setBlock("");
    } catch (err) {
      console.error("Error fetching blocks:", err);
    }
  };
  useEffect(() => {
    fetchBlocks();
  }, [pageId]);

  useEffect(() => {
    setupEdit(block);
  }, [block]);

  // Handle block type selection
  const handleBlockTypeChange = (e) => {
    setBlockType(e.target.value);
  };

  const handleAddBlock = async (e) => {
    e.preventDefault();
    const blockData = {
      page_id: pageId,
      label: label,
      block_type: blockType,
      content: content ? content : "{}",
      position: parseInt(position),
      title: label,
    };

    try {
      // Create new block
      const res = await axios.post(
        `${appConfig.NODEURL}api/blocks`,
        blockData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Add content-type if sending JSON
          },
        }
      );
      //setBlocks([...blocks, res.data]);
      setBlock(res.data);
      // setupEdit(res.data);
      setLabel("");
      setBlockType("");
      setPosition((prev) => ++prev);
      // fetchBlocks();
      console.log(res.data.id);
      // resetForm();
    } catch (err) {
      console.error("Error saving block:", err);
    }
  };

  // const handleSaveBlock = async () => {
  //   try {
  //     console.log(block);
  //     //setBlocks([...blocks, res.data]);
  //     const res = await axios.put(
  //       `${appConfig.NODEURL}api/blocks/${block.id}`,
  //       block,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
  //           "Content-Type": "application/json", // Add content-type if sending JSON
  //         },
  //       }
  //     );
  //     setBlocks(
  //       blocks.map((block) => (block.id === res.data.id ? res.data : block))
  //     );
  //     // resetForm();
  //   } catch (err) {
  //     console.error("Error saving block:", err);
  //   }
  // };

  // Handle edit block
  // const handleEditBlock = (block) => {
  //   setEditingBlock(block);
  //   setBlockType(block.block_type);
  //   setContent(block.content);
  //   setPosition(block.position);
  // };

  // Handle delete block
  const handleDeleteBlock = async (id) => {
    try {
      await axios.delete(`${appConfig.NODEURL}api/blocks/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
          "Content-Type": "application/json", // Add content-type if sending JSON
        },
      });
      setBlocks(blocks.filter((block) => block.id !== id));
      setBlock("");
    } catch (err) {
      console.error("Error deleting block:", err);
    }
  };

  // Reset form after saving or canceling
  const resetForm = () => {
    setBlockType("");
    setContent("");
    setPosition("");
    setEditingBlock(null);
  };

  const setupEdit = (block) => {
    console.log(block);
    setBlock(block);
  };

  if (!pageId) {
    return null;
  }

  const BlockContent = ({ _block }) => {
    const [block, setBlock] = useState();

    const handleSaveBlock = async () => {
      try {
        //   console.log(block);
        //setBlocks([...blocks, res.data]);
        const res = await axios.put(
          `${appConfig.NODEURL}api/blocks/${block.id}`,
          block,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
              "Content-Type": "application/json", // Add content-type if sending JSON
            },
          }
        );
        console.log(res.data);
        // fetchBlocks();
        // setBlocks(
        //   blocks.map((block) => (block.id === res.data.id ? res.data : block))
        // );
        setBlock(res.data);
        // resetForm();
      } catch (err) {
        console.error("Error saving block:", err);
      }
    };
    useEffect(() => {
      setBlock(_block);
    }, []);

    const handleChange = (event) => {
      setBlock((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    };
    if (!block) {
      return null;
    }
    return (
      <div>
        <div
          style={{
            display: "flex",
            gap: 10,
            margin: 20,
            alignItems: "center",
            backgroundColor: theme.palette.grey[200],
            width: "fit-content",
            padding: 5,
            borderRadius: 5,
          }}
        >
          <InputBase
            name="label"
            onChange={handleChange}
            className="textField"
            value={block.label}
          />
          <InputBase
            name="position"
            onChange={handleChange}
            className="textField"
            type="number"
            value={block.position}
            style={{ width: 50 }}
          />
          <div>{block.block_type}</div>
          <ConfirmButtons
            icon={<DeleteForeverIcon />}
            action={handleDeleteBlock}
            args={block.id}
          />
          <Button sx={{ ...theme.typography.button }} onClick={handleSaveBlock}>
            Update
          </Button>

          {/* <Select
    size="small"
    value={block.block_type}
    // onChange={handleBlockTypeChange}
    variant="outlined"
    required
  >
    <MenuItem value="Hero">Hero</MenuItem>
    <MenuItem value="RichText">RichText</MenuItem>
    <MenuItem value="PlayList">PlayList</MenuItem>
  </Select> */}
        </div>
        <div>
          {block.block_type === "RichText" && <Rte block={block} />}
          {block.block_type === "PlayList" && <PlaylistForm block={block} />}
          {block.block_type === "Hero" && <HeroForm block={block} />}
        </div>
      </div>
    );
  };

  const backToBlocks = () => {
    // bandaid for discontinuity
    // setBlock(false);
    fetchBlocks();
  };
  // Main view
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {/* Form for adding/updating blocks */}

      <Box mb={3}>
        <form onSubmit={(e) => handleAddBlock(e)}>
          {/* {!block && ( */}
          <Zoom in={true}>
            <Box
              spacing={2}
              style={{
                // display: block.id ? "none" : "flex",
                display: "flex",
                gap: 10,
                alignItems: "center",
              }}
            >
              <div xs={12}>
                <InputBase
                  value={label}
                  onChange={(event) => setLabel(event.target.value)}
                  placeholder="Label"
                  className="textField"
                  required
                ></InputBase>
              </div>
              <div xs={12}>
                <Select
                  size="small"
                  value={blockType}
                  onChange={handleBlockTypeChange}
                  displayEmpty
                  variant="outlined"
                  required
                >
                  <MenuItem value="" disabled>
                    blocks...
                  </MenuItem>
                  <MenuItem value="Hero">Hero</MenuItem>
                  <MenuItem value="RichText">RichText</MenuItem>
                  <MenuItem value="PlayList">PlayList</MenuItem>
                </Select>
              </div>

              <div xs={12}>
                <InputBase
                  label="Position"
                  className="textField"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  variant="outlined"
                  type="number"
                  style={{ width: 50 }}
                />
              </div>
              <div xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  type="submit"
                  // onClick={handleSaveBlock}
                >
                  Add Block
                  {/* {editingBlock ? "Update Block" : "Add Content"} */}
                </Button>
              </div>
            </Box>
          </Zoom>
          {/* )} */}
        </form>
      </Box>
      {/* Display existing blocks */}
      <Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 15,
            border: "1px solid black",
            alignItems: "center",
            padding: 10,
            borderRadius: 5,
            width: "fit-content",
          }}
        >
          {block && (
            <Zoom in={true}>
              <div
                onClick={() => setBlock(false)}
                style={{
                  display: "flex",
                  gap: 20,
                  alignItems: "center",
                  cursor: "pointer",
                  backgroundColor: theme.palette.grey[400],
                  padding: 10,
                  margin: 10,
                  borderRadius: 10,
                  boxShadow: theme.shadows[6],
                }}
              >
                <IconButton
                  style={{ boxShadow: theme.shadows[6] }}
                  // sx={{ ...theme.typography.button }}
                  onClick={backToBlocks}
                >
                  <CloseIcon />
                </IconButton>

                <div>
                  <Button
                    sx={{
                      ...theme.typography.button,
                      boxShadow: theme.shadows[6],
                      // display: "id" in block ? "block" : "none",
                    }}
                    //onClick={() => setupEdit(block)}
                    onClick={() => setBlock(false)}
                  >
                    {block.label}
                  </Button>
                </div>
              </div>
            </Zoom>
          )}
          <div
            style={{
              ...theme.typography.h6,
              backgroundColor: theme.palette.grey[300],
              borderRadius: 5,
              // display: block ? "none" : "block",
            }}
          >
            Blocks
          </div>

          {true &&
            blocks
              .filter((b) => block.id !== b.id)
              .map((block, index) => (
                <Zoom key={index} in={true}>
                  <div key={index}>
                    <Button
                      sx={{
                        ...theme.typography.button,
                        // display: "id" in block ? "block" : "none",
                      }}
                      onClick={() => setupEdit(block)}
                    >
                      {block.label}
                    </Button>
                  </div>
                </Zoom>
              ))}
        </div>
        <BlockContent _block={block} />
      </Box>
    </Box>
  );
};

export default BlocksCRUD;
