import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";

import Dancers from "./pages/Dancers";
import Register from "./pages/Register";
import SurveyResults from "./pages/SurveyResults";
import Survey from "./pages/Survey";
import FlingPage from "./pages/FlingPage";
import Test from "./pages/Test";

import UserAgreement from "./pages/UserAgreement";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Schedule2024 from "./pages/Schedule2024";
import Schedule from "./pages/Schedule";
import PpCheckout from "./pages/PpCheckout";
import PayPalReturn from "./pages/PayPalReturn";
import PayPalAltCheckOut from "./pages/PayPalAltCheckOut";

import SendMail from "./components/DancerForm/SendEmail";

import RichTextEditor from "./pages/RichTextEditor";

import ManageNavigation from "./pages/ManageNavigation";
// import Braintree from "./pages/Braintree";
import ManageCms from "./pages/ManageCms";
// import EditPage from "./components/EditPage";
import VerifyEmail from "./pages/VerifyEmail";
import ManageUsers from "./pages/ManageUsers";
import UserManage from "./pages/UserManage";
import LoginRegister from "./pages/LoginRegister";
import ManageEvents from "./pages/ManageEvents";

import MuiSize from "./components/MuiSize";

import Cms from "./pages/Render";

import "./App.css";

import Theme from "./Theme";

export const RefreshContext = React.createContext();

export default function App() {
  const [trig, setTrg] = useState(0);
  const refreshPage = () => {
    setTrg(Math.random());
  };
  return (
    <Router>
      <Theme>
        <MuiSize />
        <RefreshContext.Provider value={refreshPage}>
          <Routes>
            <Route path="/" element={<Register />} />

            <Route path="/dancers" element={<Dancers trig={trig} />} />
            <Route path="/navmgr" element={<ManageNavigation trig={trig} />} />
            <Route path="/cms" element={<ManageCms trig={trig} />} />
            <Route path="/users" element={<ManageUsers trig={trig} />} />
            <Route path="/events" element={<ManageEvents trig={trig} />} />

            <Route path="/surveyresults" element={<SurveyResults />} />
            <Route path="/survey" element={<Survey />} />
            <Route path="/fling" element={<FlingPage />} />
            <Route path="/test" element={<Test />} />
            <Route path="/user-agreement" element={<UserAgreement />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/schedule2024" element={<Schedule2024 />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/checkout" element={<PpCheckout />} />
            <Route path="/return" element={<PayPalReturn />} />
            <Route path="/altcheckout" element={<PayPalAltCheckOut />} />
            {/* <Route path="/editpage/:id" element={<EditPage />} /> */}
            <Route path="/verifyemail" element={<VerifyEmail />} />
            <Route path="/usermanage" element={<UserManage />} />
            <Route path="/login" element={<LoginRegister />} />
            <Route path="/rte" element={<RichTextEditor />} />

            <Route path="/sendmail" element={<SendMail />} />
            <Route path="/cms/:id/:slug" element={<Cms />} />
          </Routes>
        </RefreshContext.Provider>
      </Theme>
    </Router>
  );
}
