import React, { createContext, useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
import axios from "axios";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
// import Slide from "@mui/material/Slide";
import List from "@mui/material/List";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
import { useTheme, useMediaQuery } from "@mui/material";
// import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Zoom from "@mui/material/Zoom";
import Divider from "@mui/material/Divider";
import { Helmet, HelmetProvider } from "react-helmet-async";
// import { red } from "@mui/material/colors";
import DropDown from "./DropDown";
import { appConfig } from "../../config.js";
import Policies from "./Policies";
import Footer from "./Footer";

import HideOnScroll from "./HideOnScroll.js";
import isUserValidated from "../../util/isUserValidated";
// import { Link } from "react-router-dom";

// require("./header.css");

export default function Header(props) {
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);

  const handleLeave = () => {
    console.log("leaving");
    setAnchorEl(false);
  };

  const handleClick = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    console.log("closign");
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const menus = ["myDropdown", "myDropdown2"];

  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const bigger = useMediaQuery(theme.breakpoints.up("sm"));

  const [nav, setNav] = useState([]); // State for navigation items

  const fetchNavigation = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${appConfig.NODEURL}api/nested-nav`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the Authorization header
        },
      });
      setNav(response.data); // Populate navigation state
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching navigation:", error);
    }
  };

  useEffect(() => {
    fetchNavigation();
    window.onclick = function (event) {
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    };
  }, []);

  const mItems = [
    {
      text: "Home",
      link: "https://www.louisvillecountrydancers.org/",
    },
    {
      text: "Dancing With Us",
      link: "https://www.louisvillecountrydancers.org/first-time-1",
      children: [
        {
          text: "First Time?",
          link: "https://www.louisvillecountrydancers.org/first-time-1",
          external: false,
        },
        {
          text: "Schedule & Location",
          link: "https://www.louisvillecountrydancers.org/dance-with-us-1",
          extrnal: false,
        },
        {
          text: "Calendar",
          link: "https://www.louisvillecountrydancers.org/calendar",
        },
      ],
    },
    {
      text: "Follow Us",
      link: "https://louisvillecountrydancers.us17.list-manage.com/subscribe?u=00926c5355e581ebf49150412&id=f470676b67",
      children: [
        {
          text: "NewsLetter",
          link: "https://louisvillecountrydancers.us17.list-manage.com/subscribe?u=00926c5355e581ebf49150412&id=f470676b67",
          external: true,
        },
        {
          text: "FaceBook",
          link: "https://www.facebook.com/louisvillecountrydancers/",
          extrnal: true,
        },
        {
          text: "Instagra",
          link: "https://www.instagram.com/louisvillecountrydancers/",
          external: true,
        },
      ],
    },
    {
      text: "Our Organization",
      link: "https://www.louisvillecountrydancers.org/board",
      children: [
        {
          text: "Board of Directors",
          link: "https://www.louisvillecountrydancers.org/board",
          external: false,
        },
        {
          text: "ByLaws",
          link: "https://www.louisvillecountrydancers.org/bylaws",
          extrnal: false,
        },
        {
          text: "Board Meeting Minutes",
          link: "https://www.louisvillecountrydancers.org/minutes",
          external: false,
        },
        {
          text: "Code of Conduct",
          link: "https://www.louisvillecountrydancers.org/code-of-conduct",
        },
        {
          text: "Contact Us",
          link: "https://www.louisvillecountrydancers.org/contact",
        },
      ],
    },
    {
      text: "Fleur Registration",
      link: "/",
      children: [
        {
          text: "Fleur Registration",
          link: "/",
        },
        {
          text: "Fleur Schedule",
          link: "/schedule2024",
        },
        {
          text: "Survey2026",
          link: "/survey",
        },
      ],
    },
    // {
    //   text: "Fleur de Lis Fling",
    //   link: "/",
    //   children: [
    //     {
    //       text: "Fling Registration",
    //       link: "/",
    //     },
    //     {
    //       text: "Fling Schedule",
    //       link: "/schedule2024",
    //     },
    //   ],
    // },
  ];

  const aItems = isUserValidated()
    ? [
        {
          text: "Admin",
          link: "/",
          children: [
            {
              text: "Admin",
              link: "",
            },
            {
              text: "Dancers",
              link: "/dancers",
            },

            {
              text: "users",
              link: "/users",
            },
          ],
        },
        {
          text: "CMS",
          link: "/",
          children: [
            {
              text: "CMS",
              link: "/cms",
            },
            {
              text: "cms",
              link: "/cms",
            },
            {
              text: "nav manager",
              link: "/navmgr",
            },
            {
              text: "events",
              link: "/events",
            },
          ],
        },
      ]
    : [];

  const menuItems = [...mItems, ...aItems, ...nav];

  // const menuItemsMulti = [
  //   { text: "Home", link: "#home" },
  //   {
  //     text: "Dancing With Us",
  //     menuItems: [
  //       { text: "First Time", link: "linkhere" },
  //       { text: "Schedule and Location", link: "anotherlink" },
  //     ],
  //   },
  //   { text: "Playing Music", link: "#schedule" },
  //   { text: "Contact", link: "#contact" },
  // ];

  const closeMenus = () => {
    // console.log("Close MENUS");
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains("show")) {
        openDropdown.classList.remove("show");
      }
    }
  };
  // console.log(props.title);
  return (
    <HelmetProvider>
      <div style={{ border: "2px none yellow", margin: 6 }}>
        <Helmet>
          <title>{"LCD " + props.title}</title>
          <link
            rel="canonical"
            href="https://register.louisvillecountrydancers.org/"
          />
        </Helmet>
        <HideOnScroll {...props}>
          <AppBar
            className="noprint"
            id="AppBar"
            position="fixed"
            // color="primary"
            sx={{
              backgroundImage: "url(/banner4.png)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: 311, // Set height to match the image's height if needed
              width: "100%",
              padding: 0,
              height: {
                xs: 80,
                // theme.spacing(1) for extra small screens
                // sm: "none", // theme.spacing(2) for small screens
                // md: "none", // theme.spacing(3) for medium screens
                lg: 110,
                // marginTop: 4,
                // marginLeft: 14, // theme.spacing(4) for large screens
                // xl: "none", // theme.spacing(5) for extra-large screens
              },
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <div
              id="Container"
              sx={{
                // margin: "auto",
                // marginLeft: 200,
                // marginRight: 0,
                margin: 0,
                padding: 0,
                display: "flex",
                border: "1px solid black",
                width: "fit-content",
              }}
            >
              <Toolbar
                style={{
                  border: "4px none yellow",
                  margin: 0,
                  padding: 0,
                  //   width: "fit-content",
                  //   position: "relative",
                  // paddingTop: 0,
                  position: "relative",
                }}
              >
                <a
                  href="https://www.louisvillecountrydancers.org/"
                  style={{
                    // color: "#ffffff",
                    fontFamily: "Roboto",
                    fontSize: "1em",
                    position: "absolute",
                    margin: 0,
                    padding: 0,
                    left: 0,
                    top: 0,

                    textDecoration: "none",
                  }}
                >
                  <Box
                    // className="box"
                    sx={{
                      ...theme.typography.h6,
                      // backgroundImage: "url('/banner2.png')",
                      // opacity: 0.2,
                      // backgroundColor: "rgba(255,0,0,0.6)",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 1,
                      // backgroundColor: theme.palette.primary.contrastText,
                      color: theme.palette.info.dark,
                      margin: 0.1,
                      padding: {
                        xs: 0,
                        sm: 0.5,
                      },
                      fontFamily: "Roboto",
                      borderRadius: 1,
                      margin: {
                        xs: 3,
                        // marginTop: 34, // theme.spacing(1) for extra small screens
                        // sm: "none", // theme.spacing(2) for small screens
                        // md: "none", // theme.spacing(3) for medium screens
                        lg: 2,
                        // marginTop: 4,
                        // marginLeft: 14, // theme.spacing(4) for large screens
                        // xl: "none", // theme.spacing(5) for extra-large screens
                      },
                      width: {
                        xs: 500,
                        sm: 500,
                        md: 500,
                        lg: 200,
                        xl: 200,
                        // xl: 300,
                        // marginTop: 4,
                        // marginLeft: 14, // theme.spacing(4) for large screens
                        // xl: "none", // theme.spacing(5) for extra-large screens
                      },
                    }}
                  >
                    <div style={{ width: "100%", position: "relative" }}>
                      <div
                        style={{
                          width: "100%",
                          height: 50,
                          position: "absolute",
                          left: 0,
                          top: 0,
                          backgroundImage: "url(/banner4.png)",
                          opacity: 0,
                        }}
                      ></div>
                      <div
                        style={{
                          fontWeight: "bold",
                          position: "absolute",
                          fontFamily: "Roboto",
                          letterSpacing: 2,
                          left: 0,
                          top: 0,
                          opacity: 0.6,
                          borderRadius: 10,
                          backgroundColor: theme.palette.primary.dark,
                          color: theme.palette.primary.contrastText,
                        }}
                      >
                        Louisville Country Dancers
                      </div>
                    </div>
                  </Box>
                </a>
                {/* Desktop Menu */}
                <Box
                  sx={{
                    marginLeft: 30,
                    display: {
                      xs: "none",
                      lg: "flex",
                      flexWrap: "wrap",

                      paddingTop: 30,
                    },
                    border: "2px none blue",
                    // width: 1000,
                  }}
                >
                  {menuItems.map((menu, index) => {
                    return (
                      <DropDown
                        side={false}
                        key={index}
                        menu={menu}
                        closeMenus={closeMenus}
                      />
                    );
                  })}

                  <div style={{ display: "none" }}>
                    <div
                      onMouseEnter={handleClick}
                      id="menu1"
                      onMouseLeave={handleLeave}
                    >
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        Dashboard
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <div onMouseLeave={handleLeave}>
                          <MenuItem onClick={handleClose}>Profile</MenuItem>
                          <MenuItem onClick={handleClose}>My account</MenuItem>
                          <MenuItem onClick={handleClose}>Logout</MenuItem>
                        </div>
                      </Menu>
                    </div>
                    <div
                      onMouseEnter={handleClick}
                      id="menu2"
                      onMouseLeave={handleLeave}
                    >
                      <Button
                        id="button2"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        Dashboard
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "button2",
                        }}
                      >
                        <div onMouseLeave={handleLeave}>
                          <MenuItem onClick={handleClose}>Profile</MenuItem>
                          <MenuItem onClick={handleClose}>My account</MenuItem>
                          <MenuItem onClick={handleClose}>Logout</MenuItem>
                        </div>
                      </Menu>
                    </div>
                  </div>

                  <a
                    href="/fling?header=true"
                    style={{
                      display: "none",
                      fontFamily: "Roboto",
                      fontWeight: 400,
                      fontSize: 13,
                      fontStyle: "normal",
                      letterSpacing: 2,
                      textTransform: "uppercase",
                      textDecoration: "none",
                      marginLeft: "1em",
                      //   padding: "1em 1.5em 1em 1.5em !important",
                      // display: "block",
                      backgroundColor: theme.palette.secondary.main,
                      color: theme.palette.secondary.contrastText,
                      borderRadius: 300,
                      //   position: "absolute",
                      //   right: 0,
                      padding: "10px 20px 10px 20px",
                    }}
                  >
                    Fleur Registration
                  </a>
                  <pre style={{ display: "none" }}>
                    {JSON.stringify(theme.typography.menuitem, null, 3)}
                  </pre>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: {
                        xs: -25, // theme.spacing(1) for extra small screens
                        // sm: -45, // theme.spacing(2) for small screens
                        // md: -35, // theme.spacing(3) for medium screens
                        lg: -10, // theme.spacing(4) for large screens
                        xl: -20, // theme.spacing(5) for extra-large screens
                      },
                    }}
                  >
                    <Policies />
                  </Box>
                </Box>
                {/* Mobile Menu Button */}
                <Box
                  sx={{
                    display: {
                      position: "absolute",
                      left: 0,
                      top: 0,
                      xs: "flex",
                      lg: "none",
                    },
                  }}
                >
                  <Policies />
                </Box>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{
                    display: {
                      position: "fixed",
                      right: 0,
                      xs: "flex",
                      lg: "none",
                    },
                  }}
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </div>
          </AppBar>
        </HideOnScroll>
        <Box
          sx={{
            width: "100%",
            padding: 0,
            height: {
              xs: 85,
              // theme.spacing(1) for extra small screens
              // sm: "none", // theme.spacing(2) for small screens
              // md: "none", // theme.spacing(3) for medium screens
              lg: 130,
              // marginTop: 4,
              // marginLeft: 14, // theme.spacing(4) for large screens
              // xl: "none", // theme.spacing(5) for extra-large screens
            },
          }}
        ></Box>
        {/* Mobile Drawer */}
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          sx={{ display: { xs: "flex", lg: "none" } }}
        >
          <Box
            sx={{
              width: 250,
              backgroundColor: theme.palette.primary.dark,
              height: "100vh",
            }}
            role="presentation"
            onClick={handleDrawerToggle}
            onKeyDown={handleDrawerToggle}
          >
            <div style={{ position: "absolute", left: -20 }}>X</div>
            <List>
              {menuItems.map((menu, index) => {
                // console.log(menu);
                return (
                  <DropDown
                    side={true}
                    key={index}
                    menu={menu}
                    closeMenus={closeMenus}
                  />
                );
              })}
            </List>
            <Divider />
          </Box>
          <Box>
            <div>Test</div>
            <Policies />
          </Box>
        </Drawer>
        <Zoom in={true}>
          <div>{props.children}</div>
        </Zoom>
      </div>
      <Footer />
    </HelmetProvider>
  );
}
