import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { appConfig } from "../../config";
import { TextField, Button, Box, ClickAwayListener } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import JWTRegister from "./JWTRegister";
import { useContext } from "react";
import { RefreshContext } from "../../Routes"; // Import the context

import { useLocation, useNavigate } from "react-router-dom";

function isTokenExpired(token) {
  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
  return decoded.exp < currentTime;
}

const Login = ({ setShow = () => {}, setLoggedIn = () => {} }) => {
  const location = useLocation();
  const { returnTo } = location.state || "";
  const navigate = useNavigate();
  const refresh = useContext(RefreshContext); // Access the refresh function
  const theme = useTheme();
  const [email, setEmail] = useState(localStorage.getItem("lastEmail") || "");
  const [password, setPassword] = useState(
    localStorage.getItem("lastPass") || ""
  );
  const [message, setMessage] = useState("");
  const [userId, setUserId] = useState(
    localStorage.getItem("lastUserId") || ""
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    const chk = token ? isTokenExpired(token) : undefined;
    console.log(chk);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${appConfig.NODEURL}api/auth/login`,
        {
          email,
          password,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Add content-type if sending JSON
          },
        }
      );
      // console.log(response.data.token);
      localStorage.setItem("lastEmail", email);
      localStorage.setItem("lastPass", password);
      localStorage.setItem("lastUserId", userId);
      refresh();
      setMessage("You are logged in");
      setEmail("");
      setPassword("");
      setShow(false);
      setLoggedIn(true);
      localStorage.setItem("token", response.data.token);
      navigate(returnTo, {
        state: {},
      });
    } catch (error) {
      console.error(error);
      console.log("Login failed " + error?.response?.data?.message);
      setMessage(error?.response?.data?.message);
      setUserId(error?.response?.data?.userId);
    }
  };
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const sendReset = async () => {
    // console.log(email);
    const res = await axios.post(
      `${appConfig.NODEURL}api/auth/send-reset`,
      { email: email },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the Authorization header
        },
      }
    );
    // console.log(res.data);
    setMessage(res.data);
  };
  const resend = () => {
    axios.post(
      `${appConfig.NODEURL}api/auth/resendVerify`,
      { email: email, userId: userId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the Authorization header
        },
      }
    );
  };
  // const [tid,setTid] = useState("");
  let tid = 0;

  const logout = () => {
    localStorage.removeItem("token");
    refresh();
  };

  return (
    <ClickAwayListener onClickAway={() => setShow(true)}>
      <Box
        onMouseEnter={() => clearTimeout(tid)}
        onMouseLeave={() => (tid = setTimeout(() => setShow(true), 2000))}
        sx={{
          border: 1,
          height: 240,
          width: 380,

          flexWrap: "wrap",
          backgroundColor: "#ffffff",
          opacity: 9.5,
          borderRadius: 5,
          padding: 1,
          boxShadow: theme.shadows[10],
          position: "absolute",
          top: 150,
          left: 0,
        }}
      >
        <form onSubmit={handleSubmit}>
          {localStorage.getItem("token") ? (
            <Button sx={{ ...theme.typography.button }} onClick={logout}>
              Logout
            </Button>
          ) : (
            <div>
              <div style={{ display: "flex", gap: 5 }}>
                <Button sx={{ ...theme.typography.button }} type="submit">
                  Login
                </Button>
                <div
                  onClick={() => setMessage("")}
                  style={{ color: "#000000", backgroundColor: "#ffffff" }}
                >
                  {message}
                </div>
              </div>
              <TextField
                style={{ width: 200 }}
                size="small"
                type="email"
                placeholder="Email"
                value={email || ""}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <TextField
                style={{ width: 100 }}
                size="small"
                type="password"
                placeholder="Password"
                value={password || ""}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          )}

          <Button
            style={{
              display: message === "Email Not Verified" ? "block" : "none",
            }}
            onClick={resend}
          >
            Resend Verify Email
          </Button>
        </form>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <Button
            onClick={sendReset}
            style={{
              ...theme.typography.label,
              backgroundColor: theme.palette.primary.main,
              // display: localStorage.getItem("token") ? "none" : "inline",
              display: emailRegex.test(email) ? "inline" : "none",
              height: "fit-content",
            }}
          >
            Send Reset
          </Button>
        </div>
        {localStorage.getItem("token") ? "" : <JWTRegister />}
      </Box>
    </ClickAwayListener>
  );
};

export default Login;
