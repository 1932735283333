import React from "react";
import {
  TextField,
  Checkbox,
  Select,
  MenuItem,
  FormControlLabel,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

// Main View Edit Form, inline with dancers list,
const DancerFormField = ({ field, index, handleChange, validateBoxes }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        border: "2px none black",
        display: "flex",
        flexWrap: "wrap",
        verticalAlign: "middle",
        alignItems: "center",
        gap: 10,
      }}
    >
      <TextField
        style={{ minWidth: 280 }}
        label="First Name (required)"
        name="firstname"
        id={"firstname" + index}
        value={field.firstname}
        onChange={(event) => handleChange(event, index)}
        onKeyUp={validateBoxes}
      />
      <TextField
        style={{ minWidth: 280 }}
        label="Last Name (required)"
        name="lastname"
        id={"lastname" + index}
        value={field.lastname}
        onChange={(event) => handleChange(event, index)}
        onKeyUp={validateBoxes}
      />
      <TextField
        style={{ minWidth: 280 }}
        label={index === 0 ? "Email (required)" : "Email (optional!)"}
        name="email"
        id={"email" + index}
        value={field.email}
        onChange={(event) => handleChange(event, index)}
        onKeyUp={validateBoxes}
      />
      <TextField
        style={{ minWidth: 280 }}
        label="Phone (optional)"
        name="phone"
        id={"phone" + index}
        value={field.phone}
        onChange={(event) => handleChange(event, index)}
      />
      <FormControlLabel
        labelPlacement="top"
        key={"checkboxdietary"}
        label="Snack Pref"
        control={
          <Select
            size="small"
            labelId="dietLabel"
            id="dietary"
            name="dietary"
            key={"dietary"}
            onChange={(event) => handleChange(event, index)}
            // label="Dietary"
            value={field.dietary}
          >
            <MenuItem key={"menuItem" + 0} value="omnivore">
              omnivore
            </MenuItem>
            <MenuItem key={"menuItem" + 1} value="vegetarian">
              vegetarian
            </MenuItem>
            <MenuItem key={"menuItem" + 2} value="vegan">
              vegan
            </MenuItem>
            <MenuItem selected key={"menuItem" + 3} value="unknown">
              unknown
            </MenuItem>
          </Select>
        }
      />
      <FormControlLabel
        // labelPlacement="bottom"
        control={
          <Checkbox
            name="housing"
            checked={field.housing}
            onChange={(event) => handleChange(event, index)}
          />
        }
        label="Req Housing"
      />
      <FormControlLabel
        labelPlacement="top"
        key={"checkboxwatz"}
        label="Sunday Waltzing"
        control={
          <Select
            size="small"
            id="waltzattend"
            name="waltzattend"
            key={"waltzattend"}
            onChange={(event) => handleChange(event, index)}
            label="Waltz Attendance"
            value={field.waltzattend}
          >
            <MenuItem key={"menuItem" + 0} value="select...">
              select...
            </MenuItem>

            <MenuItem key={"menuItem" + 2} value="maybe">
              maybe
            </MenuItem>
            <MenuItem key={"menuItem" + 5} value="likely">
              likely
            </MenuItem>
            <MenuItem key={"menuItem" + 6} value="unlikely">
              unlikely
            </MenuItem>
          </Select>
        }
      />
      <FormControlLabel
        // labelPlacement="bottom"
        control={
          <Checkbox
            name="student"
            checked={field.student}
            onChange={(event) => handleChange(event, index)}
          />
        }
        label="Student"
      />
    </div>
  );
};

export default DancerFormField;
